html {
  height: 100%;
  font-size: 16px;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 16px;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

@media (min-width: 400px) {
  .app {
    max-width: 400px;
    margin: auto;
  }
}

/*# sourceMappingURL=index.31a81533.css.map */
